import compose from 'lyjs/es/fp/compose'
import createRestfulApi from 'lyjs/es/request/createRestfulApi'
import {headerAuth} from 'lyjs/es/request/HeaderAuth'

import {AUTH_HEADER_NAME, KINGS_LANDING_API_ENDPOINT} from 'services/constants'

const KinsLanding = compose(
  headerAuth(AUTH_HEADER_NAME),
  createRestfulApi
)({
  baseURL: KINGS_LANDING_API_ENDPOINT
})

export default KinsLanding
