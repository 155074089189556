import {createReducer} from 're-reducer'
import updateHelper from 'immutability-helper'

import {prefix, initialState} from './selectors'

function statusEnhancer(next) {
  return (state, action) => {
    const {
      payload,
      meta: {
        pending
      } = {},
      error
    } = action

    if (pending || error) {
      const nextState = updateHelper(state, {
        $merge: {
          pending: !error,
          error: error ? payload : null
        }
      })

      return nextState
    }

    return next(state, action)
  }
}

const reducer = createReducer({
  prefix,
  initialState
})

const {
  register
} = reducer

export const update = register(
  'UPDATE',
  statusEnhancer((state, action) => {
    const {
      payload: {
        token,
        mobile
      }
    } = action

    const nextState = updateHelper(state, {
      $merge: {
        token,
        mobile,
        authorized: !!token,
        pending: false,
        error: null
      }
    })

    return nextState
  })
)

export default reducer
