export const APP_NAME = process.env.REACT_APP_NAME
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_DESCRIPTION = process.env.REACT_APP_DESCRIPTION
export const APP_SHA = process.env.REACT_APP_SHA || Date.now()

export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME

export const TOKEN_CACHE_KEY = process.env.REACT_APP_TOKEN_CACHE_KEY
export const AUTH_HEADER_NAME = process.env.REACT_APP_AUTH_HEADER_NAME
export const KINGS_LANDING_API_ENDPOINT = process.env.REACT_APP_KINGS_LANDING_API_ENDPOINT
