import {decode} from 'jsonwebtoken'
import {SubmissionError} from 'redux-form'
import {message} from 'antd'

import {authorize as authorizeRequest} from 'services/request'
import tokenCache from 'services/cache/token'
import authenticateApi from 'services/api/kingsLanding/authenticate'
import {createError} from 'services/middlewares/errorHandle'
import {tokenSelector, authorizedSelector} from './selectors'
import {update} from '.'

export function unauthorize() {
  return (dispatch) => {
    dispatch(update({
      token: null,
      mobile: null
    }))

    tokenCache.destroy()
  }
}

export function authorize(token) {
  return (dispatch, getState) => {
    if (!token) {
      dispatch(unauthorize())

      return
    }

    const state = getState()
    const prevToken = tokenSelector(state)
    const authorized = authorizedSelector(state)

    if (token === prevToken && authorized) {
      return
    }

    return new Promise((resolve) => {
      resolve(decode(token))
    })
    .then((data) => {
      const {
        mobile,
        exp
      } = data

      if (exp !== undefined && exp * 1e3 < Date.now()) {
        dispatch(unauthorize())

        return
      }

      authorizeRequest(token)
      tokenCache.write(token)

      dispatch(update({
        token,
        mobile
      }))
    })
    .catch((err) => {
      dispatch(update.error(err))
    })
  }
}

export function requestToken(mobile, code, register, callback) {
  return (dispatch) => {
    authenticateApi
      .requestToken(mobile, code)
      .then((data) => {
        const {
          succeeded,
          token,
          user: {
            new_user
          } = {},
          errors
        } = data

        if (!succeeded) {
          if (callback) {
            callback(new SubmissionError(errors))
          }

          return
        }

        dispatch(authorize(token))

        if (callback) {
          callback()
        }

        if (new_user) {
          message.success('成功注册并登录')
        } else {
          if (register) {
            message.success('成功登录已存在账号')
          }
        }
      })
      .catch((err) => {
        if (callback) {
          callback(new SubmissionError({
            _: register ? '注册失败' : '登录失败'
          }))
        }

        dispatch(update.error(err))
      })
  }
}

export function requestCode(mobile, callback) {
  return (dispatch) => {
    authenticateApi
      .requestCode(mobile)
      .then((data) => {
        const {
          succeeded,
          errors
        } = data

        if (!succeeded) {
          const err = Error('Request code failed.')

          Object.assign(err, {
            errors
          })

          const meta = {
            message: '获取验证码错误',
            description: Object.values(errors).join(',')
          }

          dispatch(createError(err, {meta}))

          return
        }

        if (callback) {
          callback()
        }

        message.success('验证码发送成功')
      })
      .catch((err) => {
        if (callback) {
          callback(err)
        }

        dispatch(createError(err))
      })
  }
}
