import KingsLanding from 'services/request/KingsLanding'

class Authenticate extends KingsLanding {
  requestCode(mobile) {
    return this
      .custom('codes')
      .post({
        mobile
      })
  }

  requestToken(mobile, code) {
    return this
      .custom('tokens')
      .post({
        mobile,
        code
      })
  }
}

export default new Authenticate()
