import {applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'

import errorHandle from 'services/middlewares/errorHandle'

const middlewares = [thunk, errorHandle]
const enhancers = []

if (process.env.NODE_ENV !== 'production') {
  if (typeof window.devToolsExtension === 'function') {
    enhancers.push(window.devToolsExtension())
  }
}

const enhancer = compose(
  applyMiddleware(...middlewares),
  ...enhancers
)

export default enhancer
