import tokenCache from 'services/cache/token'

export const prefix = 'profile'
export const initialState = {
  token: tokenCache.read(),
  mobile: null,
  authorized: false,
  pending: true,
  error: null
}

export function tokenSelector(state) {
  return state[prefix].token
}

export function mobileSelector(state) {
  return state[prefix].mobile
}

export function authorizedSelector(state) {
  return state[prefix].authorized
}

export function pendingSelector(state) {
  return state[prefix].pending
}

export function errorSelector(state) {
  return state[prefix].error
}
